export const useFetchActivity = () => {
    const activity = ref(null);
    const isLoading = ref(false);
    const error = ref(null);

    const supabase = useSupabaseClient();
    const user = useSupabaseUser();

    const fetchActivity = async (activityId) => {
        try {
            isLoading.value = true;

            let queryBuilder = supabase
                .from('activities')
                .select(`
                    *,
                    city:cities!activities_city_id_fkey (
                        id,
                        name,
                        slug
                    ),
                    region:regions!activities_region_id_fkey (
                        id,
                        name,
                        slug
                    ),
                    country:countries!activities_country_id_fkey (
                        id,
                        name,
                        slug,
                        code,
                        region_display
                    ),
                    category:categories!activities_category_id_fkey (
                        id,
                        name
                    ),
                    schedules:activity_schedules!activity_schedules_activity_id_fkey (
                        id,
                        monday_open,
                        monday_all_day,
                        monday_morning_opening,
                        monday_morning_closing,
                        monday_afternoon_opening,
                        monday_afternoon_closing,
                        tuesday_open,
                        tuesday_all_day,
                        tuesday_morning_opening,
                        tuesday_morning_closing,
                        tuesday_afternoon_opening,
                        tuesday_afternoon_closing,
                        wednesday_open,
                        wednesday_all_day,
                        wednesday_morning_opening,
                        wednesday_morning_closing,
                        wednesday_afternoon_opening,
                        wednesday_afternoon_closing,
                        thursday_open,
                        thursday_all_day,
                        thursday_morning_opening,
                        thursday_morning_closing,
                        thursday_afternoon_opening,
                        thursday_afternoon_closing,
                        friday_open,
                        friday_all_day,
                        friday_morning_opening,
                        friday_morning_closing,
                        friday_afternoon_opening,
                        friday_afternoon_closing,
                        saturday_open,
                        saturday_all_day,
                        saturday_morning_opening,
                        saturday_morning_closing,
                        saturday_afternoon_opening,
                        saturday_afternoon_closing,
                        sunday_open,
                        sunday_all_day,
                        sunday_morning_opening,
                        sunday_morning_closing,
                        sunday_afternoon_opening,
                        sunday_afternoon_closing
                    ),
                    reviews:activity_reviews!activity_reviews_activity_id_fkey (
                        id,
                        review_text,
                        review_name,
                        review_rating,
                        review_date
                    ),
                    user_stats:user_activities_stats!user_activities_stats_activity_id_fkey (
                        liked,
                        saved,
                        done
                    )
                `)
                .eq('id', activityId)
                .is('deleted_at', null)
                .single();

            const { data, error: queryError } = await queryBuilder;

            if (queryError) throw queryError;

            if (data) {
                const result = {
                    ...data,
                    photos: [
                        data.image_url_0,
                        data.image_url_1,
                        data.image_url_2,
                    ].filter(url => url !== null && url !== undefined),
                    user_stats: user.value?.id ? {
                        liked: activity.user_stats?.[0]?.liked || false,
                        saved: activity.user_stats?.[0]?.saved || false,
                        done: activity.user_stats?.[0]?.done || false
                    } : {
                        liked: false,
                        saved: false,
                        done: false
                    },
                    reviews: data.reviews || null,
                    schedules: data.schedules || null,
                    tickets: [],
                    providers: [],
                    total: 0,
                };

                activity.value = result;

                return result;
            }

            return null;
        } catch (err) {
            error.value = err.message;
            console.error('Error fetching activity:', err);
            return null;
        } finally {
            isLoading.value = false;
        }
    };

    return {
        activity,
        isLoading,
        error,
        fetchActivity,
    };
};

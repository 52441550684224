<script setup lang="ts">
defineProps({
    description:{
        type: String,
        required: true,
    },
});
const showFullDescription = ref(false);
const toggleDescription = () => {
    showFullDescription.value = !showFullDescription.value
}
</script>

<template>
    <div class="mt-2 text-white">
        <p
            :class="{'line-clamp-4':!showFullDescription}"
            class="mt-3 font-Poppins text-base font-light"
        >
            {{ description }}
        </p>
        <span class="flex justify-center mt-3 mb-4 text-center text-xs text-white/50 underline" @click="toggleDescription">
                                {{ (showFullDescription) ? "Voir moins" : "Voir plus" }}
                            </span>
    </div>
</template>

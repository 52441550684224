<script setup>
import { computed } from 'vue';
import { useActivitySchedules } from '~/composables/useActivitySchedules';

const props = defineProps({
    scheduleData: {
        type: Object,
        required: true,
    },
});

const { schedules, isOpenNow } = useActivitySchedules(
    computed(() => props.scheduleData),
);
</script>

<template>
    <div>
        <h3 class="font-Poppins text-lg text-white md:text-2xl">HORAIRES</h3>
        <div class="my-3">
            <span
                class="focus-visible:ring-primary-500 inline-flex flex-shrink-0 items-center gap-x-1.5 rounded-md bg-white px-4 py-1.5 text-sm font-medium text-violet shadow-sm hover:bg-gray-200 focus:outline-none focus-visible:outline-0 focus-visible:ring-2 focus-visible:ring-inset disabled:cursor-not-allowed disabled:bg-gray-900 disabled:opacity-75"
            >
                {{ isOpenNow ? 'Ouvert actuellement' : 'Fermé actuellement' }}
            </span>
        </div>
        <div v-for="schedule in schedules" :key="schedule.day" class="flex">
            <span class="text-md flex-1 font-Poppins text-white">
                {{ schedule.day }}:
            </span>
            <span class="text-md flex-2 font-Poppins text-white">
                <template v-if="!schedule.isOpen"> Fermé </template>
                <template v-else-if="schedule.isOpen && schedule.isAllDay">
                    Ouvert 24h/24
                </template>
                <template
                    v-else-if="
                        schedule.morningOpening &&
                        schedule.morningClosing &&
                        schedule.afternoonOpening &&
                        schedule.afternoonClosing
                    "
                >
                    {{ schedule.morningOpening }} -
                    {{ schedule.morningClosing }},
                    {{ schedule.afternoonOpening }} -
                    {{ schedule.afternoonClosing }}
                </template>
                <template
                    v-else-if="
                        schedule.morningOpening &&
                        !schedule.morningClosing &&
                        !schedule.afternoonOpening &&
                        schedule.afternoonClosing
                    "
                >
                    {{ schedule.morningOpening }} -
                    {{ schedule.afternoonClosing }}
                </template>
                <template
                    v-else-if="
                        schedule.morningOpening &&
                        schedule.morningClosing &&
                        !schedule.afternoonOpening &&
                        !schedule.afternoonClosing
                    "
                >
                    {{ schedule.morningOpening }} -
                    {{ schedule.morningClosing }}
                </template>
                <template
                    v-else-if="
                        !schedule.morningOpening &&
                        !schedule.morningClosing &&
                        schedule.afternoonOpening &&
                        schedule.afternoonClosing
                    "
                >
                    {{ schedule.afternoonOpening }} -
                    {{ schedule.afternoonClosing }}
                </template>
            </span>
        </div>
    </div>
</template>

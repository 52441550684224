import type { RouteLocationNormalizedLoaded } from 'vue-router';

export function useRouteUtils(route: RouteLocationNormalizedLoaded) {
    const isActivityRoute = computed(() => route.name === 'activity');

    const isDestinationRoute = computed(() => route.name === 'destination');

    const activityId = computed(() =>
        isActivityRoute.value ? route.params.id?.toString() : null,
    );

    const activitySlug = computed(() =>
        isActivityRoute.value ? route.params.slug?.toString() : null,
    );

    const destinationSlug = computed(() =>
        isDestinationRoute.value ? route.params.slug?.toString() : null,
    );

    const generateActivityUrl = (id: string | number, slug: string) =>
        `/activite/${id}-${slug}`;

    const generateDestinationUrl = (slug: string) => `/destination/${slug}`;

    return {
        isActivityRoute,
        isDestinationRoute,
        activityId,
        activitySlug,
        destinationSlug,
        generateActivityUrl,
        generateDestinationUrl,
    };
}

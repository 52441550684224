import { th } from "@faker-js/faker";

export const useFetchActivities = () => {
    const activities = ref([]);
    const totalCount = ref(0);
    const isLoading = ref(false);
    const isLoadingMore = ref(false);
    const isLoadingPrevious = ref(false);
    const error = ref(null);
    const currentOffset = ref(0);
    const hasMoreResults = ref(true);
    const store = useStore()
    const ITEMS_PER_FETCH = 10;
    const PRELOAD_THRESHOLD = 3;

    const INITIAL_RADIUS = 5;
    const MAX_RADIUS = 500;
    const RADIUS_INCREMENT = 50;

    const supabase = useSupabaseClient();
    const user = useSupabaseUser();

    const fetchActivities = async ({
                                       popular = null,
                                       offset = 0,
                                       destinationSlug = null,
                                       activityId = null,
                                       activitySlug = null,
                                       limit = ITEMS_PER_FETCH,
                                   } = {}) => {
        try {

            if (popular === true) {
                return await fetchPopularActivities({ offset, limit });
            }

            console.log('destinationSlug:', destinationSlug);
            console.log('activityId', activityId);
            console.log('activitySlug', activitySlug);
            console.log('----------------------------');

            if (activityId && activitySlug) {
                const mainActivity = await fetchSingleActivity(
                    activityId,
                    activitySlug
                );

                if (!mainActivity) {
                    throw createError({
                        statusCode: 404,
                        statusMessage: 'Activity not found',
                        fatal: true
                    })
                }

                const nearbyActivities = await fetchNearbyActivities(
                    offset = 0,
                    limit = ITEMS_PER_FETCH,
                    mainActivity.lat,
                    mainActivity.lng,
                    INITIAL_RADIUS,
                    mainActivity.id
                );

                activities.value = [mainActivity, ...nearbyActivities];
                totalCount.value = activities.value.length;
                hasMoreResults.value = false;

                return {
                    activities: activities.value,
                    count: totalCount.value,
                };
            }

            if (destinationSlug) {
                const result = await fetchDestinationActivities(
                    offset = 0,
                    limit = ITEMS_PER_FETCH,
                    destinationSlug
                );

                if (!result) {
                    throw createError({
                        statusCode: 404,
                        statusMessage: 'Destination activities not found',
                        fatal: true
                    })
                }

                activities.value = result.activities;
                totalCount.value = result.count;
                hasMoreResults.value = false;

                return result;
            }

        } catch (err) {
            error.value = err.message;
            console.error('Error fetching activities:', err);

            throw err;

        } finally {
            isLoading.value = false;
            isLoadingMore.value = false;
            isLoadingPrevious.value = false;
        }
    }

    const fetchPopularActivities = async ({
                                              offset = 0,
                                              limit = ITEMS_PER_FETCH,
                                          } = {}) => {
        try {


            if (offset === 0) {
                isLoading.value = true;

                let countQuery = supabase
                    .from('activities')
                    .select('id', { count: 'exact', head: true })
                    .is('deleted_at', null)
                    .not('region_id', 'is', null)
                    .not('city_id', 'is', null)
                    .gte('reviews_count', 10000)
                    .eq('country_id', store.exploration.id);

                const { count } = await countQuery;

                if (count) {
                    // Generate random offset within valid range
                    const maxOffset = Math.max(0, count - limit);
                    offset = Math.floor(Math.random() * maxOffset);
                }
            } else {
                isLoadingMore.value = true;
            }

            let queryBuilder = supabase
                .from('activities')
                .select(`
                    *,
                    city:cities!activities_city_id_fkey (
                        id,
                        name,
                        slug
                    ),
                    region:regions!activities_region_id_fkey (
                        id,
                        name,
                        slug
                    ),
                    country:countries!activities_country_id_fkey (
                        id,
                        name,
                        slug,
                        code,
                        region_display
                    ),
                    category:categories!activities_category_id_fkey (
                        id,
                        name
                    ),
                    user_stats:user_activities_stats!user_activities_stats_activity_id_fkey (
                        liked,
                        saved,
                        done
                    )
                `, { count: 'exact' })
                .range(offset, offset + limit - 1)
                .is('deleted_at', null)
                .not('region_id', 'is', null)
                .not('city_id', 'is', null)

                .gte('reviews_count', 10000)
                .eq('country_id', store.exploration.id)
                .order('rating', { ascending: false })
                .order('id', { ascending: false })
                .order('created_at', { ascending: false });

            if (user.value?.id) {
                queryBuilder = queryBuilder.eq('user_stats.user_id', user.value.id);
            }

            const { data: fetchedActivities, error: activitiesError, count } = await queryBuilder;

            if (activitiesError) throw activitiesError;

            if (fetchedActivities) {
                const results = fetchedActivities.map(activity => ({
                    ...activity,
                    photos: [
                        activity.image_url_0,
                        activity.image_url_1,
                        activity.image_url_2,
                    ].filter(url => url !== null && url !== undefined),
                    user_stats: user.value?.id ? {
                        liked: activity.user_stats?.[0]?.liked || false,
                        saved: activity.user_stats?.[0]?.saved || false,
                        done: activity.user_stats?.[0]?.done || false
                    } : {
                        liked: false,
                        saved: false,
                        done: false
                    }
                }));

                // Shuffle
                for (let i = results.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [results[i], results[j]] = [results[j], results[i]];
                }

                if (offset === 0) {
                    activities.value = results;
                } else {
                    activities.value = [...activities.value, ...results];
                }

                totalCount.value = count;
                currentOffset.value = offset;
                hasMoreResults.value = offset + results.length < count;

                return {
                    activities: results,
                    count,
                };
            }

            return null;
        } catch (err) {
            error.value = err.message;
            console.error('Error fetching popular activities:', err);
            return null;
        } finally {
            isLoading.value = false;
            isLoadingMore.value = false;
        }
    };

    const fetchSingleActivity = async (activityId, activitySlug) => {
        try {
            const { data: activity, error: activityError } = await supabase
                .from('activities')
                .select(`
                    *,
                    city:cities!activities_city_id_fkey (
                        id,
                        name,
                        slug
                    ),
                    region:regions!activities_region_id_fkey (
                        id,
                        name,
                        slug
                    ),
                    country:countries!activities_country_id_fkey (
                        id,
                        name,
                        slug,
                        code,
                        region_display
                    ),
                    category:categories!activities_category_id_fkey (
                        id,
                        name
                    ),
                    user_stats:user_activities_stats!user_activities_stats_activity_id_fkey (
                        liked,
                        saved,
                        done
                    )
                `)
                .eq('id', activityId)
                .eq('slug', activitySlug)
                .single();

            if (activityError) throw activityError;

            return activity ? {
                ...activity,
                photos: [
                    activity.image_url_0,
                    activity.image_url_1,
                    activity.image_url_2,
                ].filter(url => url !== null && url !== undefined),
                user_stats: user.value?.id ? {
                    liked: activity.user_stats?.[0]?.liked || false,
                    saved: activity.user_stats?.[0]?.saved || false,
                    done: activity.user_stats?.[0]?.done || false
                } : {
                    liked: false,
                    saved: false,
                    done: false
                }
            } : null;

        } catch (error) {
            if (error.code !== 'PGRST116') { // PGRST116: The result contains 0 rows
                console.error('Error fetching single activity:', error);
            }

            return null;
        }
    };

    const fetchNearbyActivities = async (
        offset = 0,
        limit = ITEMS_PER_FETCH,
        lat,
        lng,
        radius = INITIAL_RADIUS,
        excludeActivityId = null
    ) => {
        try {
            const radiusInMeters = radius * 1000;
            console.log(`Searching for activities near ${lat}, ${lng} within ${radius}km (${radiusInMeters}m)`);

            const { data: nearbyIds, error: rpcError, count } = await supabase.rpc(
                'nearby_activities',
                {
                    ref_lat: parseFloat(lat),
                    ref_lng: parseFloat(lng),
                    radius_meters: parseFloat(radiusInMeters)
                }
            );

            if (rpcError) {
                console.error('RPC Error:', rpcError);
                if (radius < MAX_RADIUS) {
                    console.log(`No results found within ${radius}km, increasing radius to ${radius + RADIUS_INCREMENT}km`);

                    return fetchNearbyActivities(lat, lng, radius + RADIUS_INCREMENT, excludeActivityId);
                }
                throw rpcError;
            }

            console.log('Nearby IDs found:', nearbyIds);

            if (!nearbyIds?.length) {
                if (radius < MAX_RADIUS) {
                    console.log(`No results found within ${radius}km, increasing radius to ${radius + RADIUS_INCREMENT}km`);

                    return fetchNearbyActivities(lat, lng, radius + RADIUS_INCREMENT, excludeActivityId);
                }
                return [];
            }

            let query = supabase
                .from('activities')
                .select(`
                    *,
                    city:cities!activities_city_id_fkey (
                        id,
                        name,
                        slug
                    ),
                    region:regions!activities_region_id_fkey (
                        id,
                        name,
                        slug
                    ),
                    country:countries!activities_country_id_fkey (
                        id,
                        name,
                        slug,
                        code,
                        region_display
                    ),
                    category:categories!activities_category_id_fkey (
                        id,
                        name
                    ),
                    user_stats:user_activities_stats!user_activities_stats_activity_id_fkey (
                        liked,
                        saved,
                        done
                    )`, { count: 'exact' }
                )
                .range(offset, offset + limit - 1)
                .is('deleted_at', null)
                .not('region_id', 'is', null)
                .not('city_id', 'is', null)
                .in('id', nearbyIds.map(item => item.id));

            if (excludeActivityId) {
                query = query.neq('id', excludeActivityId);
            }

            if (user.value?.id) {
                query = query.eq('user_stats.user_id', user.value.id);
            }

            const { data: nearbyActivities, error: activitiesError } = await query;

            if (activitiesError) throw activitiesError;

            console.log(`Found ${nearbyActivities?.length || 0} nearby activities`);

            if (!nearbyActivities?.length) return [];

            totalCount.value = count;
            currentOffset.value = offset;
            hasMoreResults.value = offset + nearbyActivities.length < count;

            return nearbyActivities.map(activity => ({
                ...activity,
                photos: [
                    activity.image_url_0,
                    activity.image_url_1,
                    activity.image_url_2,
                ].filter(url => url !== null && url !== undefined),
                user_stats: user.value?.id ? {
                    liked: activity.user_stats?.[0]?.liked || false,
                    saved: activity.user_stats?.[0]?.saved || false,
                    done: activity.user_stats?.[0]?.done || false
                } : {
                    liked: false,
                    saved: false,
                    done: false
                }
            }));
        } catch (err) {
            error.value = err.message;
            console.error('Error fetching nearby activities:', err);

            return [];
        }
    };

    const fetchDestinationActivities = async (
        offset = 0,
        limit = ITEMS_PER_FETCH,
        destinationSlug
    ) => {
        try {
            isLoading.value = true;

            const { data: destinations, error: destinationError } = await supabase
                .rpc('get_destination_by_slug', {
                    slug_param: destinationSlug
                });

            if (destinationError) throw destinationError;

            if (!destinations?.length) {
                throw createError({
                    statusCode: 404,
                    statusMessage: 'Destination not found',
                });
            }

            const destination = destinations[0];
            console.log('Found destination:', destination);

            let queryBuilder = supabase
                .from('activities')
                .select(`
                *,
                city:cities!activities_city_id_fkey (
                    id,
                    name,
                    slug
                ),
                region:regions!activities_region_id_fkey (
                    id,
                    name,
                    slug
                ),
                country:countries!activities_country_id_fkey (
                    id,
                    name,
                    slug,
                    code,
                    region_display
                ),
                category:categories!activities_category_id_fkey (
                    id,
                    name
                ),
                user_stats:user_activities_stats!user_activities_stats_activity_id_fkey (
                    liked,
                    saved,
                    done
                )
            `, { count: 'exact' })
                .range(offset, offset + limit - 1)
                .is('deleted_at', null)
                .not('region_id', 'is', null)
                .not('city_id', 'is', null);

            if (destination.type === 'city') {
                queryBuilder = queryBuilder.eq('city_id', destination.id);
            } else if (destination.type === 'region') {
                queryBuilder = queryBuilder.eq('region_id', destination.id);
            } else if (destination.type === 'country') {
                queryBuilder = queryBuilder.eq('country_id', destination.id);
            }

            if (user.value?.id) {
                queryBuilder = queryBuilder.eq('user_stats.user_id', user.value.id);
            }

            queryBuilder = queryBuilder
                .order('rating', { ascending: false })
                .order('reviews_count', { ascending: false })
                .order('created_at', { ascending: false });

            const { data: activitiesData, error: activitiesError, count } = await queryBuilder;

            if (activitiesError) throw activitiesError;

            if (!activitiesData?.length) {
                return {
                    activities: [],
                    count: 0,
                    destination
                };
            }

            const formattedActivities = activitiesData.map(activity => ({
                ...activity,
                photos: [
                    activity.image_url_0,
                    activity.image_url_1,
                    activity.image_url_2,
                ].filter(url => url !== null && url !== undefined),
                user_stats: user.value?.id ? {
                    liked: activity.user_stats?.[0]?.liked || false,
                    saved: activity.user_stats?.[0]?.saved || false,
                    done: activity.user_stats?.[0]?.done || false
                } : {
                    liked: false,
                    saved: false,
                    done: false
                }
            }));

            return {
                activities: formattedActivities,
                count,
                destination
            };

        } catch (err) {
            error.value = err.message;
            console.error('Error fetching destination activities:', err);

            throw err;
        } finally {
            setTimeout( () => {
                isLoading.value = false;
                isLoadingMore.value = false;
            }, 500)
        }
    };


    return {
        activities,
        totalCount,
        isLoading,
        isLoadingMore,
        isLoadingPrevious,
        error,
        currentOffset,
        hasMoreResults,
        fetchActivities,
        fetchPopularActivities,
        fetchSingleActivity,
        fetchNearbyActivities,
        fetchDestinationActivities,
        ITEMS_PER_FETCH,
        PRELOAD_THRESHOLD,
    };
};

<script setup>
const props = defineProps({
    rating: {
        type: Number,
        required: false,
        default: null,
    },
    count: {
        type: Number,
        required: true,
    },
});
const formattedNumber = computed(() => {
    return formatNumber(props.count);
});

function formatNumber(num) {
    let formatted;

    if (num >= 1000000) {
        formatted = (num / 1000000).toFixed(1);
        return formatted.endsWith('.0')
            ? `${formatted.slice(0, -2)}M`
            : `${formatted}M`;
    } else if (num >= 1000) {
        formatted = (num / 1000).toFixed(1);
        return formatted.endsWith('.0')
            ? `${formatted.slice(0, -2)}K`
            : `${formatted}K`;
    } else {
        return num.toLocaleString();
    }
}
</script>
<template>
    <div
        class="relative flex flex-shrink-0 items-center justify-center gap-x-1 text-xs font-medium text-[#E0E0E0]"
    >
        <span class="text-[10px] font-bold">
            {{ Number(props.rating).toFixed(1) }}
        </span>

        <span>({{ formattedNumber }})</span>
        <span>
            <svg
                class="h-3 w-3 text-gray-300"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 22 20"
            >
                <path
                    d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"
                />
            </svg>
        </span>
    </div>
</template>

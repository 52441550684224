// composables/useActivitySchedules.js
import { computed } from 'vue';

export const useActivitySchedules = (scheduleData) => {
    const formatTime = (time) => {
        if (!time) return null;
        return time.slice(0, 5); // Convert "HH:MM:SS" to "HH:MM"
    };

    const timeToMinutes = (timeString) => {
        if (!timeString || typeof timeString !== 'string') return null;
        const [hours, minutes] = timeString.split(':').map(Number);
        return hours * 60 + minutes;
    };

    const getDayKey = (dayIndex) => {
        const days = [
            'sunday',    // 0
            'monday',    // 1
            'tuesday',   // 2
            'wednesday', // 3
            'thursday',  // 4
            'friday',    // 5
            'saturday'   // 6
        ];
        return days[dayIndex];
    };

    const transformSchedules = (scheduleData) => {
        if (!scheduleData) return [];

        const days = [
            { key: 'monday', label: 'Lundi' },
            { key: 'tuesday', label: 'Mardi' },
            { key: 'wednesday', label: 'Mercredi' },
            { key: 'thursday', label: 'Jeudi' },
            { key: 'friday', label: 'Vendredi' },
            { key: 'saturday', label: 'Samedi' },
            { key: 'sunday', label: 'Dimanche' }
        ];

        return days.map(day => ({
            day: day.label,
            isOpen: scheduleData[`${day.key}_open`],
            isAllDay: scheduleData[`${day.key}_all_day`],
            morningOpening: formatTime(scheduleData[`${day.key}_morning_opening`]),
            morningClosing: formatTime(scheduleData[`${day.key}_morning_closing`]),
            afternoonOpening: formatTime(scheduleData[`${day.key}_afternoon_opening`]),
            afternoonClosing: formatTime(scheduleData[`${day.key}_afternoon_closing`])
        }));
    };

    const isOpenNow = computed(() => {
        if (!scheduleData.value) return false;

        const now = new Date();
        const currentDayKey = getDayKey(now.getDay());
        const schedule = scheduleData.value;
        const currentMinutes = now.getHours() * 60 + now.getMinutes();

        // Check if open for current day
        if (!schedule[`${currentDayKey}_open`]) {
            return false;
        }

        // Check if open 24/7
        if (schedule[`${currentDayKey}_all_day`]) {
            return true;
        }

        // Get morning and afternoon times from raw data
        const rawMorningOpening = schedule[`${currentDayKey}_morning_opening`];
        const rawMorningClosing = schedule[`${currentDayKey}_morning_closing`];
        const rawAfternoonOpening = schedule[`${currentDayKey}_afternoon_opening`];
        const rawAfternoonClosing = schedule[`${currentDayKey}_afternoon_closing`];

        // Handle continuous opening (morning to afternoon)
        if (rawMorningOpening && rawAfternoonClosing && !rawMorningClosing && !rawAfternoonOpening) {
            const openTime = timeToMinutes(rawMorningOpening);
            const closeTime = timeToMinutes(rawAfternoonClosing);
            return openTime && closeTime ? currentMinutes >= openTime && currentMinutes <= closeTime : false;
        }

        // Check morning period
        if (rawMorningOpening && rawMorningClosing) {
            const openTime = timeToMinutes(rawMorningOpening);
            const closeTime = timeToMinutes(rawMorningClosing);
            if (openTime && closeTime && currentMinutes >= openTime && currentMinutes <= closeTime) {
                return true;
            }
        }

        // Check afternoon period
        if (rawAfternoonOpening && rawAfternoonClosing) {
            const openTime = timeToMinutes(rawAfternoonOpening);
            const closeTime = timeToMinutes(rawAfternoonClosing);
            if (openTime && closeTime && currentMinutes >= openTime && currentMinutes <= closeTime) {
                return true;
            }
        }

        return false;
    });

    const schedules = computed(() => transformSchedules(scheduleData.value));

    return {
        schedules,
        isOpenNow
    };
};

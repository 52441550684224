<script setup>
import { SwiperSlide } from 'swiper/vue';
import { register } from 'swiper/element/bundle';
import 'swiper/css';
import 'swiper/css/pagination';

register();

const props = defineProps({
    photos: {
        type: Array,
        required: true,
    },
    activityId: {
        type: Number,
        required: true,
    },
});

const swiperEl = ref(null);
const gtm = useGtm();

const goToPrevSlide = () => {
    swiperEl.value?.swiper.slidePrev();
};

const goToNextSlide = () => {
    swiperEl.value?.swiper.slideNext();
};

const onSwiperSlideChange = async () => {
    if (!swiperEl.value?.swiper) return;

    const newSlideIndex = swiperEl.value.swiper.activeIndex + 1;
    const previousSlideIndex = swiperEl.value.swiper.previousIndex + 1;

    if (newSlideIndex && previousSlideIndex) {
        const direction = newSlideIndex > previousSlideIndex ? 'right' : 'left';

        gtm.trackEvent({
            event: 'swipe_picture',
            direction_swipe: direction,
            id_activity: props.activityId,
        });
    }
};
</script>

<template>
    <div>
        <swiper-container
            ref="swiperEl"
            class="h-full w-full"
            :mousewheel="true"
            :keyboard="true"
            :slides-per-view="1"
            :sticky="true"
            :loop="false"
            :lazy="true"
            :autoplay="false"
            :lazy-preload-prev-next="1"
            :pagination="true"
            :allow-touch-move="true"
            @swiperslidechange="onSwiperSlideChange"
        >
            <swiper-slide
                v-for="(photo, idx) in photos"
                :key="idx"
                class="gradient-slider relative !flex flex-row"
                :class="{
                    'swiper-slide-active': idx === 0,
                    'swiper-slide-next': idx === 1,
                }"
            >
                <img
                    :loading="idx > 1 ? 'lazy' : null"
                    class="absolute z-10 size-full object-cover object-center"
                    :src="photo"
                />
            </swiper-slide>
        </swiper-container>
        <div
            class="absolute left-0 top-0 z-20 hidden h-full w-1/2 cursor-pointer bg-red-500/50"
            @click="goToPrevSlide"
        ></div>
        <div
            class="absolute right-0 top-0 z-20 hidden h-full w-1/2 cursor-pointer bg-blue-500/50"
            @click="goToNextSlide"
        ></div>
    </div>
</template>
